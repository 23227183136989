import React, { useState, useEffect } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { Logo, MobileLogo } from '../components/home/logo'
import { ZoneSmall, ZoneLarge } from '../components/zoner'

import { SocialMedia } from '../components/nav/social'
import { Nav } from '../components/nav'
import { Links, StyledLink } from '../components/nav/link-group'

const HomeMobileLinks = () => (
  <Links>
    <StyledLink to="/work">Work</StyledLink>
    <StyledLink to="/contact">Contact</StyledLink>
    <StyledLink to="/company">Company</StyledLink>
  </Links>
)
const NavController = ({ children }) => {
  const [isActive, setActive] = useState(false)

  useEffect(() => {
    if ('ontouchstart' in window) {
      setActive(true)
      return
    }

    const checkIfMouseInWindow = e => {
      const pX = e.pageX || e.clientX
      const pY = e.pageY || e.clientY
      const isActive = !(pX < 0 || pY < 0)
      setActive(isActive)
    }

    window.addEventListener('mousemove', checkIfMouseInWindow)
    return () => {
      window.removeEventListener('mousemove', checkIfMouseInWindow)
    }
  }, [])

  useEffect(() => {
    if ('ontouchstart' in window) {
      setActive(true)
      return
    }

    const setActiveTrue = () => void setActive(true)
    const setActiveFalse = () => void setActive(false)

    window.addEventListener('mouseleave', setActiveFalse)
    window.addEventListener('mouseenter', setActiveTrue)
    document.body.addEventListener('mouseleave', setActiveFalse)
    document.body.addEventListener('mouseenter', setActiveTrue)

    return () => {
      window.removeEventListener('mouseleave', setActiveFalse)
      window.removeEventListener('mouseenter', setActiveTrue)
      document.body.removeEventListener('mouseleave', setActiveFalse)
      document.body.removeEventListener('mouseenter', setActiveTrue)
    }
  })

  return (
    <div
      css={css`
        position: fixed;
        width: 100%;
        height: 7rem;
        opacity: ${isActive ? 1 : 0};
        transform: scale(${isActive ? '1' : '1.1'});
        transition: 0.5s ease;
        z-index: 1000;
      `}
    >
      {children}
    </div>
  )
}

const TopMenu = styled.nav`
  position: absolute;
  top: 2rem;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 1000;
`

const BottomMenu = styled.div`
  position: absolute;
  bottom: 2rem;
  font-size: 1.5rem;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 1000;
`

const IndexPage = ({ data }) => {
  return (
    <>
      <ZoneSmall>
        <TopMenu>
          <SocialMedia size={2} />
        </TopMenu>
        <MobileLogo />
        <BottomMenu>
          <HomeMobileLinks />
        </BottomMenu>
      </ZoneSmall>
      <ZoneLarge>
        <NavController>
          <Nav left={null} />
        </NavController>
        <Logo />
      </ZoneLarge>
    </>
  )
}

export default IndexPage
