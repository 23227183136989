import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { mq } from '../utils'

const Logo = () => (
  <svg
    css={css`
      width: 100vw;
      height: 100vh;
      position: absolute;
      z-index: 10;
      ${mq[0]} {
        opacity: 0;
      }
    `}
    width="100%"
    height="100%"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    // preserveAspectRatio="xMidYMid meet"
  >
    <image
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xlinkHref="/logo-white.svg"
      width="100%"
      height="100%"
    />
  </svg>
)

const MobileLogo = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 10;
  background: url('/logo-white-2-lines.svg') center 47% / 60% no-repeat;
`

export { Logo, MobileLogo }
